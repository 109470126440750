<template>
  <div class="disclaimer">
    <p>本政策仅适用于小诺肌骨微信端产品或服务。最近更新日期：2022年7月。如果您有任何疑问、意见或建议，请通过以下联系方式与我们联系：</p>
    <p>电子邮件：<a href="mailto:info@noitom.com">info@noitom.com</a></p>
    <p>电 话 ：<a href="tel:+86 400-107-8298">+86 400-107-8298</a></p>
    <p>本政策将帮助您了解以下内容：</p>
    <ul>
      <li>小诺肌骨微信服务号的个人信息收集使用规则</li>
      <li>我们如何保护您的个人信息</li>
      <li>您的权利</li>
      <li>本政策如何更新</li>
      <li>如何联系我们</li>
    </ul>
    <p>诺亦腾深知个人信息对您的重要性，并会尽全力保护您的个人信息安全可靠。我们致力于维持您对我们的信任，恪守以下原则，保护您的个人信息：权责一致原则、目的明确原则、选择同意原则、最小必要原则、确保安全原则、主体参与原则、公开透明原则等。同时，诺亦腾承诺，我们将按业界成熟的安全标准，采取相应的安全保护措施来保护您的个人信息。
      请在使用我们的产品或服务前，仔细阅读并了解本《个人信息保护政策》。
    </p>
    <h2>小诺肌骨微信服务号的个人信息收集使用规则</h2>
    <h3>1、我们收集哪些您的个人信息</h3>
    <ul>
      <li>我们提供的业务功能需要依赖部分信息才得以运行。您选择使用该项业务功能，则需要向我们提供或允许我们收集的必要信息包括：姓名、所在单位、职称、手机号
        共计4类个人信息。
      </li>
      <li>您可自主选择向我们提供或允许我们收集下列信息：关注领域<br>共计1类个人信息。这些信息并非该业务功能运行所必需，但这些信息对改善服务质量、研发新产品或服务等有非常重要的意义。我们不会强制要求您提供这些信息，您如拒绝不会对使用该业务功能产生不利影响。
      </li>
    </ul>
    <h3>2、我们如何使用您的个人信息</h3>
    <ul>
      <li>对于必要的个人信息，我们未来会用来提供该项业务功能，包括且不限于资料、内容收藏，站内私信以及有针对性的内容推送等。我们也会使用上述信息来维护和改进本项业务功能，开发新的业务功能等。</li>
      <li>对于非必要的个人信息，我们会用于以下用途，包括且不限于有针对性的内容推送，线上/线下活动邀约等。</li>
    </ul>
    <h3>3、我们如何委托处理、共享、转让、公开披露您的个人信息</h3>
    <p>（1）委托处理</p>
    <p>本业务功能中某些具体的模块或功能由外部供应商提供。例如我们会聘请服务提供商来协助我们提供客户支持。</p>
    <p>对我们委托处理个人信息的公司、组织和个人，我们会与其签署严格的保密协定，要求他们按照我们的要求、本个人信息保护政策以及其他任何相关的保密和安全措施来处理个人信息。</p>
    <p>（2）共享</p>
    <p>我们不会与本公司以外的任何公司、组织和个人分享您的个人信息，除非获得您的明确同意。
      我们可能会根据法律法规规定，或按政府主管部门的强制性要求，对外共享您的个人信息。
    </p>
    <p>（3）转让</p>
    <p>我们不会将您的个人信息转让给任何公司、组织和个人，但以下情形除外：</p>
    <p>a)在获取明确同意的情况下转让：获得您的明确同意后，我们会向其他方转让您的个人信息；</p>
    <p>b)在涉及合并、收购或破产清算时，如涉及到个人信息转让，我们会在要求新的持有您个人信息的公司、组织继续受此个人信息保护政策的约束，否则我们将要求该公司、组织重新向您征求授权同意。</p>
    <p>（4）公开披露</p>
    <p>我们仅会在以下情形下，公开披露您的个人信息：</p>
    <p>a)获得您明确同意后；</p>
    <p>基于法律的披露：在法律、法律程序、诉讼或政府主管部门强制性要求的情况下，我们可能会公开披露您的个人信息。</p>
    <h3>我们如何保护您的个人信息</h3>
    <p>（一）我们已使用符合业界标准的安全防护措施保护您提供的个人信息， 防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。我们会采取一切合理可行的措施，保护您的个人信息。</p>
    <p>（二）我们会采取一切合理可行的措施，确保未收集无关的个人信息。我们只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</p>
    <p>（三）互联网环境并非百分之百安全，我们将尽力确保或担保您发送给我们的任何信息的安全性。如果我们的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</p>
    <p>（四）在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以邮件、信函、电话、推送通知等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。
      同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。
    </p>
    <h3>您的权利</h3>
    <p>按照中国相关的法律、法规、标准，以及其他国家、地区的通行做法，我们保障您对自己的个人信息行使以下权利：</p>
    <p>（一）访问您的个人信息</p>
    <p>您有权访问您的个人信息，法律法规规定的例外情况除外。</p>
    <p>如果您无法通过上述链接访问这些个人信息，您可以随时使用我们的 Web 表单联系，或发送电子邮件至<a href="mailto:info@noitom.com">info@noitom.com</a>。</p>
    <p>我们将在30天内回复您的访问请求。</p>
    <p>（二）更正您的个人信息</p>
    <p>当您发现我们处理的关于您的个人信息有错误时，您有权要求我们作出更正。您可以通过“（一）访问您的个人信息”中罗列的方式提出更正申请。</p>
    <p>如果您无法通过上述链接更正这些个人信息，您可以随时使用我们的 Web 表单联系，或发送电子邮件至<a href="mailto:info@noitom.com">info@noitom.com</a>。</p>
    <p>我们将在30天内回复您的更正请求。</p>
    <p>（三）删除您的个人信息</p>
    <p>在以下情形中，您可以向我们提出删除个人信息的请求：</p>
    <p>1、如果我们处理个人信息的行为违反法律法规；</p>
    <p>2、如果我们收集、使用您的个人信息，却未征得您的同意；</p>
    <p>3、如果我们处理个人信息的行为违反了与您的约定；</p>
    <p>4、如果您不再使用我们的产品或服务，或您注销了账号；</p>
    <p>5、如果我们不再为您提供产品或服务。</p>
    <p>若我们决定响应您的删除请求，我们还将同时通知从我们获得您的个人信息的实体，要求其及时删除，除非法律法规另有规定，或这些实体获得您的独立授权。</p>
    <p>当您从我们的服务中删除信息后，我们可能不会立即在备份系统中删除相应的信息，但会在备份更新时删除这些信息。</p>
    <p>（四）改变您授权同意的范围</p>
    <p>每个业务功能需要一些基本的个人信息才能得以完成。对于额外收集的个人信息的收集和使用，您可以随时给予或收回您的授权同意。</p>
    <p>当您收回同意后，我们将不再处理相应的个人信息。但您收回同意的决定，不会影响此前基于您的授权而开展的个人信息处理。</p>
    <p>（五）个人信息主体注销账户</p>
    <p>您随时可注销此前注册的账户，您可以通过发送邮件至<a href="mailto:info@noitom.com">info@noitom.com</a>，并提出申请的方式，要求注销您的账户。在注销账户之后，我们将停止为您提供产品或服务，并依据您的要求，
      删除您的个人信息，法律法规另有规定的除外。
    </p>
    <p>（六）约束信息系统自动决策</p>
    <p>在某些业务功能中，我们可能仅依据信息系统、算法等在内的非人工自动决策机制作出决定。如果这些决定显著影响您的合法权益，您有权要求我们作出解释，我们也将提供适当的救济方式。</p>
    <p>（七）响应您的上述请求</p>
    <p>为保障安全，您可能需要提供书面请求，或以其他方式证明您的身份。我们可能会先要求您验证自己的身份，然后再处理您的请求。</p>
    <p>对于您合理的请求，我们原则上不收取费用，但对多次重复、超出合理限度的请求，我们将视情收取一定成本费用。对于那些无端重复、需要过多技术手段（例如，需要开发新系统或从根本上改变现行惯例）、给他人合法权益带来风险或者非常不切实际（例如，涉及备份磁带上存放的信息）的请求，我们可能会予以拒绝。</p>
    <p>在以下情形中，我们将无法响应您的请求：</p>
    <p>1、与个人信息控制者履行法律法规规定的义务相关的；</p>
    <p>2、与国家安全、国防安全直接相关的；</p>
    <p>3、与公共安全、公共卫生、重大公共利益直接相关的；</p>
    <p>4、与刑事侦查、起诉、审判和执行判决等直接相关的；</p>
    <p>5、个人信息控制者有充分证据表明个人信息主体存在主观恶意或滥用权利的；</p>
    <p>6、出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；</p>
    <p>7、响应个人信息主体的请求将导致个人信息主体或其他个人、组织的合法权益受到严重损害的；</p>
    <p>涉及商业秘密的。</p>
    <h3>本政策如何更新</h3>
    <p>我们的个人信息保护政策可能变更。</p>
    <p>未经您明确同意，我们不会削减您按照本个人信息保护政策所应享有的权利。我们会在本页面上发布对本政策所做的任何变更。对于重大变更，我们还会提供更为显著的通知（包括对于某些服务，我们会通过电子邮件发送通知，说明个人信息保护政策的具体变更内容）。</p>
    <p>本政策所指的重大变更包括但不限于：</p>
    <p>1、我们的服务模式发生重大变化。如处理个人信息的目的、处理的个人信息类型、个人信息的使用方式等；</p>
    <p>2、我们在所有权结构、组织架构等方面发生重大变化。如业务调整、破产并购等引起的所有者变更等；</p>
    <p>3、个人信息共享、转让或公开披露的主要对象发生变化；</p>
    <p>4、您参与个人信息处理方面的权利及其行使方式发生重大变化；</p>
    <p>5、我们负责处理个人信息安全的责任部门、联络方式及投诉渠道发生变化时；</p>
    <p>6、个人信息安全影响评估报告表明存在高风险时。我们还会将本政策的旧版本存档，供您查阅。</p>
    <h3>如何联系我们</h3>
    <p>如果您对本个人信息保护政策有任何疑问、意见或建议，通过以下方式与我们联系：<a href="mailto:info@noitom.com">info@noitom.com</a>。</p>
    <p>一般情况下，我们将在三十天内回复。</p>
    <van-button @click="close">我已阅读以上全部内容</van-button>
  </div>
</template>
<script>
export default {
  name: "Disclaimer",
  data() {
    return {
    }
  },
  methods: {
    close() {
      window.history.go(-1);
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.disclaimer {
  padding: 0 0.16rem;
  p {
    font-size: 0.14rem;
    line-height: 0.24rem;
    margin-top: 0.2rem;
  }
  li {
    list-style: square;
    margin-left: 0.16rem;
    font-size: 0.14rem;
    line-height: 0.24rem;
    margin-top: 0.1rem;
  }
  h2 {
    font-size: 0.18rem;
    line-height: 0.24rem;
    margin-top: 0.2rem;
  }
  h3 {
    font-size: 0.16rem;
    line-height: 0.24rem;
    margin-top: 0.2rem;
  }
  a {
    color: #24bbd3;
  }
  .van-button {
    margin: 0.2rem 0;
    color: #fff;
  }
}
</style>